<template>
  <layout-landing>
    <div class="landing login">
      <header class="landing__header">
        <div class="landing__logo-container">
          <img
            alt="Logo Sayl Connect"
            class="landing__logo"
            :src="logo"
          />
        </div>

        <div class="landing__locale-container">
          <locale-select class="landing__locale" />
        </div>
      </header>

      <div class="landing__body">
        <!-- Branding -->
        <div class="landing__branding">
          <h1 class="title">{{ $t('conn3ct-wallet.login_form_title') }}</h1>
          <div class="description">
            <p v-html="$t('conn3ct-wallet.login_form_description')"></p>

            <a
              class="landing__cta"
              @click="showWalletDescription = true"
            >{{ $t('conn3ct-wallet.wallet_description_cta') }}</a>
          </div>
        </div>

        <!-- Form -->
        <div class="landing__form">
          <div
            class="landing__error"
            v-if="!$basil.isNil(error)"
          >{{ error }}</div>

          <forms-input
            class="landing__input"
            icon-post="user"
            :placeholder="$t('conn3ct-wallet.email_or_phone_placeholder')"
            v-model="email"
            :hint="$t('conn3ct-wallet.login_identifier_hint')"
          >{{ $t('conn3ct-wallet.email_or_phone_lbl') }}</forms-input>

          <forms-input
            class="landing__input"
            :placeholder="$t('conn3ct-wallet.password_placeholder')"
            :type="showPassword ? 'text' : 'password'"
            v-model="password"
          >
            {{ $t('conn3ct-wallet.password_lbl') }}
            <template #suffix>
              <div
                @click="onPassword"
                class="forms-control__post -is-clickable"
              >
                <ui-icon :glyph="showPassword ? 'visible' : 'hidden'" />
              </div>
            </template>
          </forms-input>

          <div class="login__options">
            <a
              @click="goToPasswordReset"
              class="login__password-reset"
            >{{ $t('conn3ct-wallet.login_forgot_password_action') }}</a>
          </div>

          <actions-button
            @click="onConfirm"
            class="landing__action"
            :appearance="$pepper.Appearance.PRIMARY"
            :disabled="loading || !isFilled"
            :loading="loading"
          >{{ $t('conn3ct-wallet.login_action') }}</actions-button>
        </div>

        <div class="login__separator"></div>

        <div class="login__footer">
          <h3 class="login__footer-title">{{ $t('conn3ct-wallet.login_no_account_label') }}</h3>

          <actions-button
            @click="onRegister()"
            class="login__secondary-action"
          >{{ $t('conn3ct-wallet.login_sign_up_action') }}</actions-button>
        </div>
      </div>
    </div>

    <popins-modal
      @close="showWalletDescription = false"
      position="top-center"
      :size="$pepper.Size.S"
      :visible="showWalletDescription"
    >
      <template v-slot:header>
        <h4 class="landing__about-title">{{ $t('conn3ct-wallet.wallet_description_title') }}</h4>
      </template>

      <div
        class="landing__about-description"
        v-html="$t('conn3ct-wallet.wallet_description_content')"
      ></div>

      <template v-slot:footer>
        <actions-button @click="showWalletDescription = false">{{ $t('conn3ct-wallet.wallet_description_close') }}</actions-button>
      </template>
    </popins-modal>
  </layout-landing>
</template>

<script>
import { mapState } from 'vuex'

import LayoutLanding from '@/layouts/landing.vue'
import Header from '@/components/navigation/header.vue'
import LocaleSelect from '@/components/actions/locale-select.vue'

export default {
  name: 'SaylConn3ctLogin',

  components: {
    LayoutLanding,
    Header,
    LocaleSelect,
  },

  data() {
    return {
      done: false,
      email: '',
      error: null,
      loading: false,
      password: '',
      remember: false,
      showPassword: false,
      showWalletDescription: false
    }
  },

  computed: {
    ...mapState({
      navigationParams: state => state.bootstrap.navigationParams,
      l: state => state.bootstrap.i18n.locales,
      i18n: state => state.bootstrap.i18n,
      bootstrap: state => state.bootstrap,
    }),

    locales() {
      let ret = this.l || []
      return ret.map(r => {
        return { label: r.lang, value: r.lang }
      })
    },

    isFilled() {
      return [
        this.$basil.isNil(this.email),
        this.$basil.isEmpty(this.email),
        this.$basil.isNil(this.password),
        this.$basil.isEmpty(this.password),
      ].filter(f => f === true).length === 0
    },

    merchantLogo() {
      return this.bootstrap?.data?.brand?.logo
    },

    logo() {
      if(this.merchantLogo) {
        return this.translate(this.merchantLogo.url)
      }

      return this.$root.getSrc('statics/images/sayl-logo-color.svg')
    }
  },

  methods: {
    goToPasswordReset() {
      this.$router.push({
        name: 'sayl-connect_landing-reset-password',
        params: {
          email: this.email,
        }
      }).catch(() => {})
    },

    async init() {
      try {
        const user = await this.$user.getUser()

        this.setLocale(user.lang)
        this.$router.push({ name: 'sayl-connect_dashboard-dashboard' }).catch(() => {})
      } catch(e) {
        this.$wallet.reset()
        this.$dashboard.reset()

        const navigationParamsString = window.sessionStorage.getItem('navigationParams')

        if(!navigationParamsString) {
          return
        }

        const { email, phoneNumber } = JSON.parse(navigationParamsString)

        if(email) {
          this.email = email.replace(' ', '+')
        } else {
          this.email = phoneNumber.replace(' ', '+')
        }

        window.sessionStorage.removeItem('navigationParams')
      }
    },

    onConfirm() {
      this.error = null
      this.loading = true

      if(this.email.indexOf('+') !== 0 && !this.email.includes('@')) {
        this.loading = false
        this.error = this.$t('conn3ct-wallet.login_incorrect_identifier')
        return
      }

      this.$user.login({ email: this.email, password: this.password })
        .then(user => {
          if(user.lang) {
            this.setLocale(user.lang)
          }

          this.$router.push({ name: 'sayl-connect_dashboard-dashboard' }).catch(() => {})
        })
        .catch((e) => {
          this.error = e
          if(this.$basil.get(e, 'status', 500) === 422 || this.$basil.get(e, 'status', 500) === 404) {
            this.error = this.$t('conn3ct-wallet.login_incorrect_credentials')
          } else if(this.$basil.get(e, 'status', 500) === 429) {
            this.error = this.$t('conn3ct-wallet.login_too_many_attempts')
          }
        })
        .finally(() => this.loading = false)
    },

    onPassword() {
      this.showPassword = !this.showPassword
    },

    onPressEnter(e) {
      if(e.keyCode === 13 && this.isFilled && !this.loading) {
        this.onConfirm()
      }
    },

    onRegister() {
      this.$router.push({ name: 'sayl-connect_landing-register' }).catch(() => {})
    },
  },

  mounted() {
    this.selectedLocale = { ...this.i18n.locale }

    this.init()

    window.addEventListener('keypress', this.onPressEnter)
  },

  beforeDestroy() {
    window.removeEventListener('keypress', this.onPressEnter)
  }
}
</script>
